html, body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}
/* Fade in animation */
.fade-in {
  opacity: 0;
  animation: fadeIn 1.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Fade-in up animation */
.fade-in-up {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1.5s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Slide-in from left */
.slide-in-left {
  opacity: 0;
  transform: translateX(-30px);
  animation: slideInLeft 1.5s forwards;
}

@keyframes slideInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Slide-in up animation */
.slide-in-up {
  opacity: 0;
  transform: translateY(30px);
  animation: slideInUp 1.5s forwards;
}

@keyframes slideInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Scale-up on hover */
.scale-up-on-hover {
  transition: transform 0.3s ease;
}

.scale-up-on-hover:hover {
  transform: scale(1.05);
}

/* Style for the main description text */
.des h1 {
  color: #FFFFFF; /* Standard white text */
  font-family: 'Arial', sans-serif; /* Clean and readable */
  font-size: 1.2em;
  line-height: 1.6;
}

.name {
  font-family: 'Lora', serif; /* Elegant, refined font */
  /* font-size: 1.6em; */
  font-style: italic;
}


/* Animation classes */
.delay-100 {
  animation-delay: 100ms;
}

.delay-200 {
  animation-delay: 200ms;
}

.delay-300 {
  animation-delay: 300ms;
}

/* Enhance existing highlight class */
.highlight {
  color: #DA70D6;
  font-family: 'Lora', serif;
  font-style: italic;
  font-size: 1.6em; /* Larger to show importance */
  transition: color 0.3s ease;
  position: relative;
  display: inline-block;
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #DA70D6;
  transition: width 0.3s ease;
}

.highlight:hover::after {
  width: 100%;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 10px;
  border: 2px solid #1a1a1a;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border: 1.5px solid #1a1a1a;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #333 #1a1a1a;
}